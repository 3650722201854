body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "PromixaNova";
	src: local("PromixaNova"),
		url("/src/assets/fonts/ProximaNovaFont.otf") format("opentype");
}

.icon-wrapper {
	position: relative;
	padding: 0px 30px;
	width: 175px;
}

.icon-wrapper .anticon {
	position: absolute;
	top: -2px;
	width: 16px;
	height: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 16px;
	line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
	color: #1890ff;
}

.icon-wrapper .anticon:first-child {
	left: 0;
}

.icon-wrapper .anticon:last-child {
	right: 0;
}
